

console.log("Photo Lightbox JS is loading");

// I cannot figure out how to make the script work while placed here so instead it is on the Sidenav layout page

    // Rewrite this function:

    function meirSquare(number) {

    }

    // as this instead:

    window.meirSquare = function(number) {
      return number * number;
    }
