// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

// MEIR NOTE -- If using as template, make sure to add controller to Index.js

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "output" ]

  greet() {
    console.log("Hello, Stimulus!", this.element)
  }
}
