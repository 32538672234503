// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.


import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "jquery"
import "@rails/request.js"
import "@hotwired/turbo-rails"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

import 'bootstrap'

import "controllers"

require("trix")
require("@rails/actiontext")
require("http")
require("src/photo_lightbox")

import "../src/photo_lightbox.js"
import "../src/testing_jquery.js"
import "../src/currency_field.js"
