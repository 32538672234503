
/* Enable Bootstrap Tooltips everywhere */
$(function () {
  $('[data-toggle="tooltip"]').tooltip()
  $('[data-bs-toggle="popover"]').popover()
})



/* Create Show/Hide Content on Clickable Nav */
$(function() {
    var curPage="";
    $("#menu a").click(function() {
        if (curPage.length) {
            $("#"+curPage).hide();
        }
        curPage=$(this).data("page");
        $("#"+curPage).show();
    });
});


/*
  window.onload = function() {
      alert( "yoooooo" );
  };
*/
  console.log("We in bizzness!");


document.addEventListener("turbo:frame-render", function (e) {

  // Nav Pills on Requisitions Show Page Invoice Turboframe
  $(function() {
     $(".req-invoices-pills").click(function() {
        // remove classes from all
        $(".req-invoices-pills").removeClass("active");
        // add class to the one we clicked
        $(this).addClass("active");
     });
  });

  // Enable tooltips
  $(function () {
    $('[data-toggle="tooltip"]').tooltip()
    $('[data-bs-toggle="popover"]').popover()
  })

  // Get the element with id="defaultOpen" and click on it
  document.getElementById("defaultOpen").click();


  // Reopen the Edit buttons after Turboframe reload
  document.getElementById("master-edit-btn").click();

  $('.survey_form').hide();
  $('.survey_results').show();

});

document.addEventListener("turbo:click", function (e) {
  $('.image-upload-spinner').show();
});

document.addEventListener("turbo:submit-start", function (e) {
  $('.image-upload-spinner').show();
});

document.addEventListener("turbo:submit-end", function (e) {
  $('.image-upload-spinner').hide();
});

document.addEventListener("turbolinks:load", function() {

      /* On Dailylog Multistep, show and hide all Edit buttons */
      $(".dailylog-start-edit-btn").click(function() {
          $('.dailylog-edit-btn').fadeIn(200);
          $('.dailylog-done-btn').fadeIn(200);
          $('.dailylog-dropdown').hide();
          $('.multistep-fieldgroup').addClass("edit-state", 5000);
          $('.next-prev-btns').hide();
      });


      $(".dailylog-done-btn").click(function() {
          $('.dailylog-edit-btn').fadeOut(200);
          $('.dailylog-close-edit-btn').fadeOut(200);
          $('.add-worklog-turbo').fadeOut(200);
          $(this).hide();
          $('.dailylog-dropdown').fadeIn(200);
          $('.multistep-fieldgroup').stop(true, true).removeClass("edit-state", 50);
          $('.next-prev-btns').show();

          /* $(this).addClass('btn-secondary dailylog-start-edit-btn');
          $(this).removeClass('btn-warning dailylog-done-btn');
          $('.edit-btn-text').replaceWith("Edit"); */
      });


      /* On Dailylog Multistep, hide 'Add Log Button' when editing a worklog */
      $(".worklog-edit-link").click(function() {
          $('#addWorklogButton').hide();
          $('#cancelWorklogButton').hide();
      });

      /* On Dailylog Multistep, hide 'Add Material Button' when editing a material */
      $(".material-edit-link").click(function() {
          $('#addMaterialButton').hide();
      });

      /* On Dailylog Multistep, hide 'Add Equipment Button' when editing a equipment */
      $(".equipment-edit-link").click(function() {
          $('#addEquipmentButton').hide();
      });

/*

      $( "a" ).click(function( event ) {
          alert( "Thanks for visiting!" );
      });

      function showapproveform(event) {
          event.preventDefault();
        $( ".approve-submittal-form" ).show();
        $( ".submittal-form-btns" ).hide();
      };


      function show_reject_form(event) {
          event.preventDefault();
        $( ".reject-submittal-form" ).show();
        $( ".submittal-form-btns" ).hide();
      };

      function hide_forms(event) {
          event.preventDefault();
        $( ".approve-submittal-form" ).hide();
        $( ".reject-submittal-form" ).hide();
        $( ".submittal-form-btns" ).show();
      };
*/

      $("#speclist_category, #contract_speclist_id").addClass("form-select");

      $( ".schedule-container" )
        .mouseenter(function() {
          $('.schedule-detail').fadeIn(200);
        })
        .mouseleave(function() {
          $('.schedule-detail').fadeOut(200);
      });



      function scrollToAnchor(aid){
          var aTag = $("a[name='"+ aid +"']");
          $('html,body').animate({scrollTop: aTag.offset().top},'slow');
      }

      $("#workflow-link").click(function() {
         scrollToAnchor('workflow');
      });

      $(".card:first").addClass('highlight_first');

      $('.previous-activity-divider-col').insertAfter(".card:first");

  });

  /* Workflow Delete Llink_to AJAX */

  window.addEventListener("load", () => {
    const links = document.querySelectorAll("a[data-remote]");
    links.forEach((element) => {
      element.addEventListener("ajax:success", () => {
        alert("The article was deleted.");
      });
    });
  });


/* Submittal Subcomment Form AJAX */
  window.addEventListener("load", () => {
  const element = document.querySelector(".move-sequence");
  element.addEventListener("ajax:success", (event) => {
    const [_data, _status, xhr] = event.detail;
    element.insertAdjacentHTML("beforeend", xhr.responseText);
  });
  element.addEventListener("ajax:error", () => {
    element.insertAdjacentHTML("beforeend", "<p>ERROR</p>");
  });
});
