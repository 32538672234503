


// MEIR NOTE -- If using as template, make sure to add controller to Index.js

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    reset() {
      this.element.reset()
    }
}
