// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

// MEIR NOTE -- If using as template, make sure to add controller to Index.js

import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

export default class extends Controller {
  static targets = ["checklistSelect"]

  change(event) {
    let country = event.target.selectedOptions[0].value
    let target = this.checklistSelectTarget.id
    let project = this.data.get("project")

    get(`/projects/${project}/tasks/new/checklistselect?target=${target}&name=${country}`, {
      responseKind: "turbo-stream"
    })
  }
}
