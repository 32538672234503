// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { Application } from "@hotwired/stimulus"

import HelloController from "./hello_controller"
import DragController from "./drag_controller"
import NestedFormController from "./nested_form_controller"
import CategoryController from "./category_controller"
import ChecklistitemController from "./checklistitem_controller"
import InvoicespecController from "./invoicespec_controller"
import ResetFormController from "./reset_form_controller"
import SearchFormController from "./search_form_controller"

window.Stimulus = Application.start()
Stimulus.register("hello", HelloController)
Stimulus.register("drag", DragController)
Stimulus.register("nested-form", NestedFormController)
Stimulus.register("category", CategoryController)
Stimulus.register("checklistitem", ChecklistitemController)
Stimulus.register("invoicespec", InvoicespecController)
Stimulus.register("reset_form", ResetFormController)
Stimulus.register("search-form", SearchFormController)
